
<template>
    <div class="pager">
        <div class="nav">
            <van-nav-bar :title="$t('lockup.nav_title')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div class="box">
            <div class="title">
                <span>{{ $t('lockup.title') }}</span>
            </div>
            <div class="desc">
                <span>{{ $t('lockup.desc') }}</span>
            </div>
            <!-- 产品亮点 -->
            <div class="card">
                <div class="highlights">
                    <span style="color: #f0b82d;">{{ $t('lockup.highlights.title') }}</span>
                </div>
                <div class="row">
                    <div class="column" v-for="(item, key) in highlights.items" :key="key">
                        <div class="item_title">
                            <span>{{ $t(item.title) }}</span>
                        </div>
                        <div class="item_desc">
                            <span>{{ $t(item.desc) }}</span>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top: 28px;">
                    <div class="column" v-for="(item, key) in highlights.desc" :key="key">
                        <img :src="item.icon" alt style="width: 36px;height: 36px;">
                        <div class="desc_title">
                            <span>{{ $t(item.title) }}</span>
                        </div>

                    </div>
                </div>

            </div>
            <!-- 举个例子 -->
            <div class="card">
                <div class="highlights">
                    <span style="color: #f0b82d;">{{ $t('lockup.example.title') }}</span>
                </div>
                <div class="column" style="text-align:left; align-items: start; margin-top:12px ;">
                    <span class="highlights">{{ $t('lockup.example.income') }}</span>

                    <span class="desc_title" style="width: 100%; margin-top: 5px;"
                        v-html="$t('lockup.example.income_desc')" />
                </div>
            </div>
            <!-- 关于违约金 -->
            <div class="card">
                <div class="column" style="text-align:left; align-items: start; margin-top:12px ;">
                    <span class="highlights">{{ $t('lockup.example.breach') }}</span>

                    <span class="desc_title" style="width: 100%; margin-top: 5px;"
                        v-html="$t('lockup.example.breach_desc')" />
                </div>
            </div>

            <van-button class="go" @click="go">{{ $t('lockup.go') }}</van-button>

        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            highlights: {
                items: [
                    {
                        title: 'lockup.highlights.items.0.title',
                        desc: 'lockup.highlights.items.0.desc'
                    },
                    {
                        title: 'lockup.highlights.items.1.title',
                        desc: 'lockup.highlights.items.1.desc'
                    },
                ],
                desc: [
                    {
                        title: "lockup.highlights.desc.0",
                        icon: require("../../assets/img/lockup/0.svg")
                    },
                    {
                        title: "lockup.highlights.desc.1",
                        icon: require("../../assets/img/lockup/1.svg")
                    },
                    {
                        title: "lockup.highlights.desc.2",
                        icon: require("../../assets/img/lockup/2.svg")
                    }

                ]
            }
        }
    },
    methods: {
        go() {
            this.$router.push({ path: '/Lockup/Financial' })
        }
    }


}

</script>

<style scoped lang="less">
.pager {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #E3E6EA;
    overflow-y: scroll;

}

.nav {
    position: fixed;
    width: 100vw;
    font-size: 1.12rem;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
}

.box {
    margin: 70px 16px 0 16px;

    .title {
        font-size: 1.12rem;
        color: #333;
        font-weight: 800;
    }

    .desc {
        margin: 26px 0 27px 0;
        text-align: left;
        color: #333;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .card {
        margin-top: 10px;
        width: 100%;
        background: #fff;
        border-radius: 10px;
        padding: 14px 19px;

    }

    .highlights {
        color: #363636;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
    }

    .item_title {
        color: #333;
        font-size: 15px;
        font-weight: 400;
    }

    .item_desc {
        color: #777;
        font-size: 11px;
        font-weight: 400;
    }

    .desc_title {
        width: 84px;
        margin-top: 13px;
        color: #333;
        font-size: 11px;
        font-weight: 400;
    }

    .go {
        margin-top: 10px;
        width: 100%;
        border-radius: 5px;
        background: #f0b82d;
        color: #FFF;
        font-size: 16px;
        font-weight: 400;
    }


}
</style>